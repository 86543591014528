import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "../assets/index.sass"
import TestimonialsBar from "../pages/testimonials-bar"

const IndexPage = () => (


  <div className="home">

    <div className="index-video-container">
  
        <div className="animated-intro">
          
          <p className="text-animation-slider slide-1">Serving The Chicagoland Area</p>
          <p className="text-animation-slider slide-2">We speak Spanish</p>
          <p className="text-animation-slider slide-3">We solve for X</p>

        </div>

        {/* <video playsInline autoPlay loop muted>
          <source src={"https://firebasestorage.googleapis.com/v0/b/tonysdrain-ef299.appspot.com/o/homepage-hydro-jet.mp4?alt=media&token=039dfd63-09c1-4737-9fcf-7f51302595c2"} type="video/mp4" />
        </video> */}
        <div className="home-image">
          <Image  filename="homedeginone.jpeg" />
        </div>
    </div>


    <div className="contact-info">
      <div className="contact-image-wrapper">
        <Image filename="email.png" />
        <a href="mailto:thex.signaturecompany@gmail.com">thex.signaturecompany@gmail.com </a>
      </div>
      {/* <div className="contact-image-wrapper">
        <Image filename="phone.png" />
        <a href="tel:630-596-7712">(630) 596-7712</a>
      </div>
      <div className="contact-image-wrapper">
        <Image filename="location.png" />      
        <a href="https://goo.gl/maps/ti5nMpJjuqwb3CaV9">302 S. Lake Street, Aurora, IL 60506</a>
      </div> */}
      {/* <div className="map-image-wrapper">
        <a href="https://goo.gl/maps/ti5nMpJjuqwb3CaV9">
          <Image filename="tonys-googlemap.png" />      
        </a>
      </div> */}
      {/* <div className="emergency-service">
        <h1>
          We offer emergency service 24/7 
        </h1>
        <p>
          When the unexpected happens, count on Tony's to be there.
        </p>
      </div> */}

    </div>
    {/* <div className="company-experience-homepage">
      <div className="company-experience-info">
        <h2>WE HAVE OVER 10 YEARS OF EXPERIENCE</h2>
        <p> We specialize in Sewer and Plumbing Camera Inspection Services, Hot Hydro Jetting, 
          Sewer Pipe Cleaning, and Plumbing Repair Services, serving Aurora and the Chicagoland area. 
          With over 10 years of experience, 
          our highly-trained team with the latest equipment is here to help you with any of your sewer repair and maintenance services. </p>
        <p>Contact us today for all your sewer pipe cleaning and repair needs in Aurora or the Chicagoland area!</p>
      </div>
      <iframe src='https://www.youtube.com/embed/8VsSPjGmg-w'
        frameborder='0'
        allowfullscreen
        title='video'/>
    </div> */}

    <div className="services-container">
      <h1>Our Services</h1>
      <div className="desktop-services-container">

      <div className="services">
          <Link to="/services/landscaping">
              <p>
                Landscaping
              </p>
              <ul className="sewer-services-list">
                <li>Mulching</li>
                <li>Spring, fall, and summer cleanings</li>
                <li>Tree and plant planting</li>
                <li>Landscape lighting</li>
                <li>Retaining walls, patios, patio walkways</li>
                <li>Fencing</li>
                <li>Decking</li>
                <li>Pergolas</li>
                <li>Privacy walls</li>
                <li>Awnings</li>
                <li>Custom pieces</li>
                <li>And more</li>
              </ul>
          </Link>
        </div>

        <div className="services">
          <Link to="/services/electrical">
              <p>
              electrical
              </p>
              <ul className="sewer-services-list">
                <li>Home interior lighting and electrical wiring</li>
                <li>TV installations and home theaters</li>
                <li>Exterior seasonal and permanent lighting</li>
                <li>Landscape lighting</li>
                <li>Appliance repairs</li>
            </ul>
          </Link>
        </div>
        <div className="services">
          <Link to="/services/painting">
              <p>
                painting
              </p>
              <ul className="sewer-services-list">
                  <li>Interior Painting</li>
                  <li>Exterior Painting</li>
                  <li>Decorative Painting and Finishes</li>
                  <li>Cabinet Refinishing</li>
                  <li>Deck and fence painting/staining</li>
                  <li>Shelves, walls interior painting</li>
                  <li>Refurbish painting - metal, wood, plastic, etc.</li>
                  <li>Custom piece painting</li>
                  <li>Drywall Repair and Preparation</li>
              </ul>
          </Link>
        </div>
        
      <div className="services">
          <Link to="/services/seasonalandpermanent">
            <p>
              Seasonal and permanent
            </p>
            <ul className="sewer-services-list">
                <li>Pressure Washing</li>
                <li>Carpet Cleaning</li>
                <li>Spring Cleaning and Maintenance</li>
                <li>Summer Landscaping and Garden Upkeep</li>
                <li>Fall Gutter Cleaning and Leaf Removal</li>
                <li>Winterizing</li>
                <li>Foundation and Structural Repairs</li>
                <li>Home Renovation and Remodeling</li>
                <li>Interior Roof Repairs</li>
            </ul>
          </Link>
        </div>
        <div className="services">
          <Link to="/services/sewerandplumbing">
                <p>
                Sewer and Plumbing
                </p>
                <ul className="sewer-services-list">
                  <li>Drain Cleaning</li>
                  <li>Emergency Plumbing Services</li>
                  <li>Leak Detection and Repair</li>
                  <li>Sewer Line Repair and Replacement</li>
                  <li>Water Heater Installation and Repair</li>
                  <li>Septic System Maintenance</li>
                  <li>Pipe Installation and Repair</li>
                  <li>Fixture Installation (Sinks, Toilets, Faucets)</li>
                  <li>Backflow Prevention</li>
                  <li>Hydro Jetting</li>
              </ul>
          </Link>
        </div>
        <div className="services">
          <Link to="/services/custompieces">
              <p>
                Custom Pieces
              </p>
              <ul className="sewer-services-list">
                  <li>Custom Furniture Design and Crafting</li>
                  <li>Personalized Woodworking Projects</li>
                  <li>Custom Tailored Clothing</li>
                  <li>Unique Metalwork and Sculptures</li>
              </ul>
          </Link>
        </div>
      </div>
    </div>

    <div className="commercial-work">
      <h1>
        COMMERCIAL SERVICE EXPERIENCE
      </h1>
      <p>
        Commercial, industrial and municipal drains and sewers need servicing also. Foreign objects and other belongings can cause blockages that require professional help. This includes but is not limited to commercial bathrooms, kitchens, floors, and anywhere else.
      </p>
      <Link to="/commercial">
        <button>Read More</button>
      </Link>
    </div>

    {/* <div className="social-media">
      <h1>Follow Us Online</h1>
      <div className="services">
        <a href="https://www.facebook.com/tonysdrainsewercleaning">
          <img  src="https://user-images.githubusercontent.com/26069518/97885912-95252b00-1ced-11eb-9109-1faa980c635b.png"/>
        </a>
        <p>See what we like and talk with our team</p>
      </div>
      <div className="services">
        <a href="https://www.instagram.com/tonysdrainsewercleaning_inc">
          <img  src="https://user-images.githubusercontent.com/26069518/97885300-cbae7600-1cec-11eb-926d-f387546b5baf.png"/>
        </a>
        <p>Follow us on Instagram for tips and guides</p>
      </div>
      <div className="services">
        <a href="https://twitter.com/Tony01926898">
          <Image  filename="twitter-black.png"/>
        </a>
        <p>We're on Twitter</p>
      </div>
      <div className="services">
        <a href="https://www.youtube.com/channel/UCrw7rtH4Oj6HPViXcHTX0ZA">    
          <img  src="https://user-images.githubusercontent.com/26069518/97885670-50998f80-1ced-11eb-9d59-ee75df5126e1.png"/>  
        </a>
        <p>Check out video's of our operations and get to meet our team</p>
      </div>
    </div>   */}
    
    <div className="client-statements">
      <h2>What are clients are saying</h2>
      <TestimonialsBar />
      <Link to="/testimonials"><button>Read More</button></Link>
    </div>
    <div className="faq">
      <h2>WHAT IS THE MOST COMMON REASON TO HAVE A SEWER BACKUP?</h2>
      <div className="faq-answer">
        <p>
          <span className="bold-text">Tree roots intrusion</span>, 
          roots will grow into joints and create a nest inside the pipe and catch toilet paper, 
          human feces that will eventually create a sewer backup.
        </p>
        <Link to="/faq">
          <button>Have More Questions?</button>      
        </Link>
      </div>
    </div>

  </div>
)

export default IndexPage
